import { ClientHistory } from '../../entities/ClientHistory';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { actionToColor, dateTimeFormater } from '../../utils';
import Pill from '../Pill';
import { Skeleton, Stack, styled } from '@mui/material';
import BoxContainer from '../BoxContainer';
import ScrollContainer from '../ScrollContainer';
import ContainerHeader from '../ContainerHeader';

const Italic = styled('span')(() => ({
	fontStyle: 'italic',
	marginTop: '0.4rem',
	fontSize: '0.85rem',
}));
interface ClientHistoryProps {
	histories: ClientHistory[];
}
export default function ClientHistoryDisplay({
	histories,
}: ClientHistoryProps) {
	const skeleton = () => {
		return [1, 2, 3].map((a, i) => (
			<TimelineItem key={i}>
				<TimelineSeparator>
					<TimelineDot style={{ borderWidth: '4px' }} />
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent
					color="textSecondary"
					sx={{ display: 'flex', gap: 2, fontSize: '0.9rem' }}
				>
					<Stack sx={{ width: '100%' }}>
						<Skeleton variant="text" />
						<Skeleton variant="text" />
						<Skeleton variant="text" />
					</Stack>
				</TimelineContent>
			</TimelineItem>
		));
	};
	return (
		<BoxContainer width="500px">
			<ContainerHeader icon={<AssignmentOutlinedIcon />}>
				Hístorico del miembro
			</ContainerHeader>

			<ScrollContainer maxHeight="280px">
				<Timeline
					sx={{
						[`& .${timelineItemClasses.root}:before`]: {
							flex: 0,
							padding: 0,
						},
						margin: 0,
					}}
				>
					{!histories.length && skeleton()}
					{histories?.map((history, index) => (
						<TimelineItem key={index}>
							<TimelineSeparator>
								<TimelineDot color="warning" style={{ borderWidth: '4px' }} />
								{histories.length > 1 && <TimelineConnector />}
							</TimelineSeparator>
							<TimelineContent
								color="textSecondary"
								sx={{ display: 'flex', gap: 2, fontSize: '0.9rem' }}
							>
								<div style={{ maxWidth: '4rem' }}>
									{dateTimeFormater(history.created_at)}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginLeft: '0.75rem',
									}}
								>
									<Pill
										text={history.action}
										color={actionToColor(history.action)}
									/>
									<Italic>{history.comments}</Italic>
									<Italic>
										Actualizado por: {history?.updated_by?.name || 'Sistema'}
									</Italic>
								</div>
							</TimelineContent>
						</TimelineItem>
					))}
				</Timeline>
			</ScrollContainer>
		</BoxContainer>
	);
}
